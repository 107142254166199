<template>
  <div>
    <div class="row">
      <!-- Customer Information -->
      <div class="col-xl-3 col-12 my-3">
        <label for="">Nom</label>
        <input type="text" v-model="customer.lastName" list="lastNames" class="form-control" />
        <datalist id="lastNames">
          <option v-for="(customer, index) in customers" :key="index++" :value="customer.lastName">
            {{ customer.lastName }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Prénom</label>
        <input type="text" v-model="customer.firstName" list="firstNames" class="form-control" />
        <datalist id="firstNames">
          <option v-for="(customer, index) in customers" :key="index++" :value="customer.fistName">
            {{ customer.fistName }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Date de naissance </label>
        <input type="date" v-model="customer.birthDate" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Adresse</label>
        <input type="text" v-model="customer.address" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Ville</label>
        <input type="text" v-model="customer.city" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone</label>
        <input type="text" v-model="customer.phone" list="phones" class="form-control" />
        <datalist id="phones">
          <option v-for="(customer, index) in customers" :key="index++" :value="customer.phone">
            {{ customer.phone }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone Fixe</label>
        <input type="text" v-model="customer.homePhone" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">E-mail</label>
        <input type="text" v-model="customer.email" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Profession</label>
        <input type="text" v-model="customer.profession" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Mot de passe</label>
        <input type="password" v-model="customer.password" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Confirmation mot de passe</label>
        <input type="password" v-model="customer.password_confirmation" class="form-control" />
      </div>

      <!-- Tutor Information -->
      <div class="col-xl-12 mt-4">
        <h4>Informations sur le tuteur</h4>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Nom du tuteur</label>
        <input type="text" v-model="customer.tutorLastName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Prénom du tuteur</label>
        <input type="text" v-model="customer.tutorFirstName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">E-mail du tuteur</label>
        <input type="text" v-model="customer.tutorEmail" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone du tuteur</label>
        <input type="text" v-model="customer.tutorPhone" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">CIN du tuteur</label>
        <input type="text" v-model="customer.tutorCin" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Adresse du tuteur</label>
        <input type="text" v-model="customer.tutorAddress" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Ville du tuteur</label>
        <input type="text" v-model="customer.tutorCity" class="form-control" />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone fixe du tuteur</label>
        <input type="text" v-model="customer.tutorHomePhone" class="form-control" />
      </div>

      <!-- Remarks -->
      <div class="col-xl-12">
        <label for="">Remarque</label>
        <textarea v-model="customer.remark" class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(customer)">
          <i class="bi bi-check-square-fill"></i> Modifer & Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // customer: {},
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),
  
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/update", data);
    },
   
  },
  beforeMount() {
    this.$store.dispatch("customer/show", this.$route.params.reference);
  },
};
</script>
